<template>
  <div class="first-screen">
    <div class="container relative">
      <div class="first-screen__bg"><img src="@/assets/icons/main-rec.svg" alt="bg"/></div>
      <p class="first-screen__back-text text-xl color-mixed">transforming&nbsp<br/>IT realities</p>

      <div class="text-content z-2 relative">
        <p class="text-xl color-mixed">Expansion opportunities</p>
        <p class="mobile text-xl color-mixed">transforming IT realities</p>
        <div class="text-content__bottom d-flex align-end">
          <p class="text-m color-mixed">CENALION's commitment to staying ahead of the technological curve ensures that <span>clients are always equipped with the latest and most impactful solutions for their digital needs.</span></p>
          <Button @click="isModal = true" icon="arrow-right" text="Join us" primary></Button>
        </div>
      </div>
    </div>
  </div>
  <UiModal v-if="isModal" @close="isModal = false"></UiModal>
</template>

<script setup>
import {ref} from "vue";
import Button from '@/components/Button.vue';
import UiModal from "@/components/Ui/UiModal.vue";

const isModal = ref(false);
</script>

<style lang="scss" scoped>
.first-screen {
  padding: 130px 0 85px;
  overflow: hidden;
  &__back-text {
    position: absolute;
    right: 80px;
    top: 30%;
    text-align: end;
    opacity: 0;
    transform: translateX(-10%);
    animation: moveRight 1s ease forwards;
    animation-delay: 0.15s;
  }
  &__bg {
    position: absolute;
    top: -115px;
    width: 609px;
    height: 394px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    animation: opacityShow 1s ease forwards;
    animation-delay: 0.5s;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .mobile {
    display: none;
  }
}

.text-content {
  width: 70%;
  &__bottom {
    margin-top: 24px;
    gap: 149px;
    p {
      width: 45%;
      opacity: 0;
      transform: translateX(-10%);
      animation: moveRight 1s ease forwards;
      animation-delay: 0.4s;
    }
  }
  button {
    width: 166px;
    opacity: 0;
    animation: opacityShow 1s ease forwards;
    animation-delay: 0.5s;
  }
  .text-xl {
    width: 70%;
    opacity: 0;
    transform: translateX(-10%);
    animation: moveRight 1s ease forwards;
  }
}

@media (max-width: 1200px) {
  .mobile {
    display: block !important;
  }
  .first-screen {
    &__back-text {
      display: none;
    }
    &__bg {
      left: auto;
      transform: translateX(0);
      right: -120px;
      top: -100px;
    }
  }
  .text-content {
    width: 95%;
    .text-xl {
      width: 100%;
    }
    &__bottom {
      gap: 50px;
      justify-content: space-between;
      p {
        width: 60%;
      }
    }
  }
}

@media (max-width: 768px) {
  .first-screen {
    padding: 24px 0 40px;
    &__bg {
      width: 320px;
      right: -80px;
      top: -125px;
      animation: none;
      opacity: 1;
    }
    &__back-text {
      animation: none;
    }
    .container {
      transform: translateY(30%);
      animation: moveCenter 1.2s ease forwards;
      opacity: 0;
    }
  }
  .text-content {
    width: 100%;
    &__bottom {
      flex-direction: column;
      align-items: flex-start !important;
      gap: 32px;
      margin-top: 16px;
      p {
        width: 100%;
        animation: none;
        opacity: 1;
        transform: translateX(0);
      }
    }
    .text-xl {
      width: 105%;
      animation: none;
      opacity: 1;
      transform: translateX(0);
    }
    button {
      animation: none;
      opacity: 1;
    }
  }
}

@media (max-width: 425px) {
  .first-screen__bg {
    right: auto;
    left: 40%;
  }
}
</style>