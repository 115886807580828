import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: function () {
      return import('../views/AboutView.vue')
    }
  },
  {
    path: '/software',
    name: 'software',
    component: function () {
      return import('../views/SoftwareView.vue')
    }
  },
  {
    path: '/team',
    name: 'team',
    component: function () {
      return import('../views/TeamView.vue')
    }
  },
  {
    path: '/support',
    name: 'support',
    component: function () {
      return import('../views/SupportView.vue')
    }
  },
  {
    path: '/custom-software',
    name: 'custom',
    component: function () {
      return import('../views/CustomSoftView.vue')
    }
  },
  {
    path: '/enterprise-software',
    name: 'enterprise',
    component: function () {
      return import('../views/EnterpriseSoftView.vue')
    }
  },
  {
    path: '/cloud-software',
    name: 'cloud',
    component: function () {
      return import('../views/CloudSoftView.vue')
    }
  },
  {
    path: '/quality-assurance',
    name: 'quality',
    component: function () {
      return import('../views/QualityView.vue')
    }
  },
  {
    path: '/agile-development',
    name: 'agile',
    component: function () {
      return import('../views/AgileDevelopmentView.vue')
    }
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: function () {
      return import('../views/ContactsView.vue')
    }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: function () {
      return import('../views/PrivacyView.vue')
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.afterEach((to, from) => {
  window.scrollTo(0, 0);
})

export default router
