<template>
  <div class="home">
    <Header ref="target" id="header"></Header>
    <FirstScreen id="block1"></FirstScreen>
<!--    <template v-if="!targetIsVisible?.value">-->
<!--      <Nav></Nav>-->
<!--    </template>-->

    <SecondScreen id="block2"></SecondScreen>
    <ThirdScreen id="block3"></ThirdScreen>
    <FourthScreen id="block4"></FourthScreen>
    <FifthScreen id="block5"></FifthScreen>
    <SixthScreen id="block6"></SixthScreen>
    <div class="scroll-wrap">
      <ScrolledText id="block7"></ScrolledText>
    </div>
    <Footer id="block8" class="screen-mt small"></Footer>
  </div>
</template>

<script setup>
import {ref, onMounted, onBeforeUnmount} from "vue";
import { useElementVisibility } from '@vueuse/core'
import Header from '@/components/Header.vue';
import Nav from '@/components/Nav.vue';
import FirstScreen from '@/components/home/FirstScreen.vue';
import SecondScreen from '@/components/home/SecondScreen.vue';
import ThirdScreen from '@/components/home/ThirdScreen.vue';
import FourthScreen from '@/components/home/FourthScreen.vue';
import FifthScreen from '@/components/home/FifthScreen.vue';
import SixthScreen from '@/components/home/SixthScreen.vue';
import ScrolledText from '@/components/ScrolledText.vue';
import Footer from '@/components/Footer.vue';

const target = ref(null);
const targetIsVisible = ref(true);
const handleScroll = () => {
  if(!!document.getElementById('header')) {
    targetIsVisible.value = useElementVisibility(document.getElementById('header'));
  }
};

onMounted(() => {
  handleScroll()
  window.addEventListener('scroll', handleScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll);
});

</script>

<style lang="scss" scoped>
.scroll-wrap {
  overflow: hidden;
}
@media (max-width: 768px) {
  .home {
    overflow: hidden;
  }
}
</style>

