<template>
  <div class="container screen-mt relative z-2">
    <ScreenTitle title="Software" type="center" width="70">Unleashing the power of developing <span>own software</span></ScreenTitle>
    <div
        class="blocks d-flex wrap"
        v-motion
        :initial="{ opacity: 0 }"
        :visibleOnce="{scale: 1, opacity: 1 }"
        :duration="400"
    >
      <!-- <template v-for="(item, id) in items" :key="item"> -->
        <div id="c1">
          <IconBlock :icon="items[0].icon" :text="items[0].text"></IconBlock>
        </div>
        <div id="c2">
          <IconBlock :icon="items[1].icon" :text="items[1].text"></IconBlock>
        </div>
        <div id="c3">
          <IconBlock :icon="items[2].icon" :text="items[2].text"></IconBlock>
        </div>
        <div id="c4">
          <IconBlock :icon="items[3].icon" :text="items[3].text"></IconBlock>
        </div>
      <!-- </template> -->
    </div>
    <Button
        @click="isModal = true"
        icon="arrow-right"
        text="Join us"
        primary
        v-motion
        :initial="{ y: 7, opacity: 0 }"
        :visibleOnce="{ y: 0, scale: 1, opacity: 1 }"
        :duration="800"
    ></Button>
    <UiModal v-if="isModal" @close="isModal = false"></UiModal>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import IconBlock from "@/components/blocks/IconBlock.vue";
import Button from '@/components/Button.vue';
import ScreenTitle from '@/components/ScreenTitle.vue';
import UiModal from '../Ui/UiModal.vue';

const isModal = ref(false);
const props = defineProps({visible: Boolean, hide: Boolean})

const items = ref([
  {
    icon: "lock",
    text: "In the ever-evolving landscape of cybersecurity, the role of sophisticated software in combating fraud has become paramount."    
  },
  {
    icon: "rec",
    text: "Our developed anti-fraud systems, powered by intricate algorithms and advanced machine learning, stand as digital guardians against malicious activities."    
  },
  {
    icon: "circle",
    text: "This software solutions tirelessly analyze vast datasets, detect patterns, and identify anomalies in real-time, acting as a vigilant shield."    
  },
  {
    icon: "protect",
    text: "By seamlessly integrating into financial transactions, online interactions, and sensitive data exchanges, CENALION anti-fraud systems not only thwart fraudulent attempts but also continually adapt to evolving tactics, staying one step ahead of cybercriminals."    
  },
])

</script>

<style lang="scss" scoped>
button {
  margin: 0 auto;
}
.blocks {
  gap: 44px;
  width: 100%;
  max-width: 1080px;
  margin: 60px auto 66px;
  & > div {
    width: calc(50% - 23px);
  }
}

@media (max-width: 1024px) {
  .blocks {
    margin: 32px auto;
    gap: 32px;
    & > div {
      width: calc(50% - 16px);
    }
  }
}

@media (max-width: 768px) {
  .blocks {
    & > div {
      width: 100%;
      max-width: 500px;
      margin: 0 auto;
    }
    & .hide {
      opacity: 0;
    }
  }
}
</style>