<template>
  <div :class="`title ${type} ${animate ? 'anim' : ''}`">
    <p class="screen-tops" v-if="title !== ''">{{ title }}</p>
    <p
       class="text-l color-mixed"
       :style="containerStyle"
       v-motion
       :initial="{ x: type == 'left' ? -40 : type == 'center' ? 0 : 40, y: type === 'center' ? 20 : 0, opacity: 0 }"
       :visibleOnce="{ x: 0, y: 0, scale: 1, opacity: 1 }"
       :duration="900"
    ><slot></slot></p>
  </div>
</template>

<script setup>
import { computed, ref, onMounted, onBeforeUnmount } from 'vue';
import { useWindowWidth } from '@/windowWidth';

const screenWidth = useWindowWidth();

const props = defineProps({
  title: String,
  type: {
    type: String,
    default: 'left'
  },
  width: {
    type: String,
    default: "100"
  },
  maxWidth: String,
  animate: Boolean
})

const containerStyle = computed(() => ({
  width: screenWidth.value > 768 ? `${props.width}%` : screenWidth.value < 550 ? '100%' : '88%',
  maxWidth: props.maxWidth !== '' ? `${props.maxWidth}px` : 'none'
}));
</script>

<style lang="scss" scoped>
.title {
  &.left {
    text-align: left;
  }
  &.right {
    text-align: right;
    .text-l {
      margin-left: auto;
    }
  }
  &.center {
    text-align: center;
    .text-l {
      margin: 0 auto;
    }
  }
  .screen-tops {
    margin-bottom: 20px;
  }

  &.anim {
    &.left .text-l {
      opacity: 0;
      transform: translateX(-10%);
      animation: moveRight 1s ease forwards;
    }

    &.right .text-l {
      opacity: 0;
      transform: translateX(10%);
      animation: moveRight 1s ease forwards;
    }

    &.center .text-l {
      opacity: 0;
      transform: translateY(10%);
      animation: moveCenter 1s ease forwards;
    }
  }
}

@media (max-width: 768px) {
  .title {
    .screen-tops {
      margin-bottom: 12px;
    }
    &.anim {
      opacity: 0;
      transform: translateY(20%);
      animation: moveCenter 1s ease forwards;
      .text-l {
        animation: none !important;
        transform: translateX(0) !important;
        opacity: 1 !important;
      }
    }
  }
}
</style>