<template>
  <div class="ui-modal-wrap d-flex justify-center align-center">
    <div class="ui-modal" :class="subscribe ? 'answer' : (!isSent && !parentCall )  ? 'main' : 'answer'">
      <div class="ui-modal__content relative d-flex">
        <svg class="cross" @click="$emit('close')" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M6 18L18 6M6 6L18 18" stroke="white" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <div v-if="subscribe && !isSent && !isSuccess" class="d-flex response subscribe column align-center">
          <p class="response__title color-mixed">Subscribe</p>
          <img src="@/assets/img/modal.png" alt="message picture" />
          <form id="subs" @submit.prevent="send('subs')">
            <div class="input d-flex align-center" :class="{'error': isError && data.name.toString().trim().length == 0}">
              <input placeholder="Name" type="text" @input="handleInput($event, 'name')" v-model="data.name" required aria-required="true" maxlength="100"/>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10ZM10 5C10.1989 5 10.3897 5.07902 10.5303 5.21967C10.671 5.36032 10.75 5.55109 10.75 5.75V10.25C10.75 10.4489 10.671 10.6397 10.5303 10.7803C10.3897 10.921 10.1989 11 10 11C9.80109 11 9.61032 10.921 9.46967 10.7803C9.32902 10.6397 9.25 10.4489 9.25 10.25V5.75C9.25 5.55109 9.32902 5.36032 9.46967 5.21967C9.61032 5.07902 9.80109 5 10 5ZM10 15C10.2652 15 10.5196 14.8946 10.7071 14.7071C10.8946 14.5196 11 14.2652 11 14C11 13.7348 10.8946 13.4804 10.7071 13.2929C10.5196 13.1054 10.2652 13 10 13C9.73478 13 9.48043 13.1054 9.29289 13.2929C9.10536 13.4804 9 13.7348 9 14C9 14.2652 9.10536 14.5196 9.29289 14.7071C9.48043 14.8946 9.73478 15 10 15Z" fill="#FF693A"/>
              </svg>
            </div>
            <div class="input d-flex align-center" :class="{'error': isError && !emailRegex.test(data.email)}">
              <input placeholder="Email" type="email" v-model="data.email" required maxlength="250"/>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10ZM10 5C10.1989 5 10.3897 5.07902 10.5303 5.21967C10.671 5.36032 10.75 5.55109 10.75 5.75V10.25C10.75 10.4489 10.671 10.6397 10.5303 10.7803C10.3897 10.921 10.1989 11 10 11C9.80109 11 9.61032 10.921 9.46967 10.7803C9.32902 10.6397 9.25 10.4489 9.25 10.25V5.75C9.25 5.55109 9.32902 5.36032 9.46967 5.21967C9.61032 5.07902 9.80109 5 10 5ZM10 15C10.2652 15 10.5196 14.8946 10.7071 14.7071C10.8946 14.5196 11 14.2652 11 14C11 13.7348 10.8946 13.4804 10.7071 13.2929C10.5196 13.1054 10.2652 13 10 13C9.73478 13 9.48043 13.1054 9.29289 13.2929C9.10536 13.4804 9 13.7348 9 14C9 14.2652 9.10536 14.5196 9.29289 14.7071C9.48043 14.8946 9.73478 15 10 15Z" fill="#FF693A"/>
              </svg>
            </div>
            <Button text="Subscribe to newsletter" icon="arrow-right" primary type="submit"></Button>
          </form>
        </div>
        <template v-else>
          <div v-if="isSent || parentCall" class="response d-flex column align-center">
            <p class="response__title color-mixed"> {{ parentCall ? parentSuccess ?  "Success" : "Oops!" : isSuccess ? "Success" : "Oops!" }} </p>
            <p class="text-m text-m-2">
              <span v-if="(parentCall && parentSuccess) || (isSent && isSuccess)">
                <span v-if="subscribe">Your data has been submitted. <br />Thank you for subscribing to our newsletter!</span>
                <span v-else>Your application has been accepted. <br/> You will be contacted shortly for further details.</span>
              </span>
              <span v-else>
                Something went wrong.<br/> Please try again!
              </span>
            </p>
            <img :src="require(`@/assets/img/${(parentCall && parentSuccess) || (isSent && isSuccess) ? 'success.png' : 'error.png'}`)" alt="picture"/>
            <Button :text="subscribe ? 'Back' : 'Continue'" icon="arrow-right" primary @click="$emit('close')"></Button>
          </div>
          <div class="left relative" v-if="!isSent && !parentCall">
            <p class="title-mobile color-mixed">Message Us</p>
            <img class="picture" src="@/assets/img/modal.png" alt="message picture" />
            <img class="lines" src="@/assets/icons/main-rec4.svg" alt="modal background" />
          </div>
          <div class="right" v-if="!isSent && !parentCall">
            <p class="title color-mixed">Message Us</p>
            <form class="modal-form d-flex column" id="forma" @submit.prevent="send">
              <div class="d-flex top">
                <div class="input d-flex align-center" :class="{'error': isError && data.name.toString().trim().length == 0}">
                  <input placeholder="Name" type="text" @input="handleInput($event, 'name')" v-model="data.name" required aria-required="true" maxlength="100"/>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10ZM10 5C10.1989 5 10.3897 5.07902 10.5303 5.21967C10.671 5.36032 10.75 5.55109 10.75 5.75V10.25C10.75 10.4489 10.671 10.6397 10.5303 10.7803C10.3897 10.921 10.1989 11 10 11C9.80109 11 9.61032 10.921 9.46967 10.7803C9.32902 10.6397 9.25 10.4489 9.25 10.25V5.75C9.25 5.55109 9.32902 5.36032 9.46967 5.21967C9.61032 5.07902 9.80109 5 10 5ZM10 15C10.2652 15 10.5196 14.8946 10.7071 14.7071C10.8946 14.5196 11 14.2652 11 14C11 13.7348 10.8946 13.4804 10.7071 13.2929C10.5196 13.1054 10.2652 13 10 13C9.73478 13 9.48043 13.1054 9.29289 13.2929C9.10536 13.4804 9 13.7348 9 14C9 14.2652 9.10536 14.5196 9.29289 14.7071C9.48043 14.8946 9.73478 15 10 15Z" fill="#FF693A"/>
                  </svg>
                </div>
                <div class="input d-flex align-center" :class="{'error': isError && !emailRegex.test(data.email)}">
                  <input placeholder="Email" type="email" v-model="data.email" required maxlength="250"/>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10ZM10 5C10.1989 5 10.3897 5.07902 10.5303 5.21967C10.671 5.36032 10.75 5.55109 10.75 5.75V10.25C10.75 10.4489 10.671 10.6397 10.5303 10.7803C10.3897 10.921 10.1989 11 10 11C9.80109 11 9.61032 10.921 9.46967 10.7803C9.32902 10.6397 9.25 10.4489 9.25 10.25V5.75C9.25 5.55109 9.32902 5.36032 9.46967 5.21967C9.61032 5.07902 9.80109 5 10 5ZM10 15C10.2652 15 10.5196 14.8946 10.7071 14.7071C10.8946 14.5196 11 14.2652 11 14C11 13.7348 10.8946 13.4804 10.7071 13.2929C10.5196 13.1054 10.2652 13 10 13C9.73478 13 9.48043 13.1054 9.29289 13.2929C9.10536 13.4804 9 13.7348 9 14C9 14.2652 9.10536 14.5196 9.29289 14.7071C9.48043 14.8946 9.73478 15 10 15Z" fill="#FF693A"/>
                  </svg>
                </div>
              </div>
              <input class="input" placeholder="Subject" type="text" v-model="data.subject"/>
              <textarea class="input textarea" placeholder="Message" v-model="data.message"></textarea>

              <div class="d-flex column">
                <label class="custom-checkbox d-flex relative" for="ch-1" @click="isChecked = !isChecked" :key="isChecked" required>
                  <input required type="checkbox" id="ch-1" :checked="isChecked"/>
                  <span class="checkmark"></span>
                  <span class="check-text">I have read and accept the <router-link to="/privacy" class="archivo text-normal color-white">Privacy Policy</router-link>.</span>
                </label>
                <label class="custom-checkbox d-flex relative" for="ch-2" @click="isChecked2 = !isChecked2" :key="isChecked2">
                  <input type="checkbox" id="ch-2" :checked="isChecked2"/>
                  <span class="checkmark"></span>
                  <span class="check-text">I want to receive notifications about the latest products, events and cutting-edge ideas.</span>
                </label>
              </div>

              <Button text="Send message" icon="arrow-right" primary type="submit"></Button>
            </form>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, watch, onMounted, onBeforeUnmount, onUnmounted} from "vue";
import Button from "@/components/Button.vue";

const isError = ref(false);
const isChecked = ref(false);
const isChecked2 = ref(false);
const emailRegex = ref(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);

const isSent = ref(false);
const isSuccess = ref(false);

const props = defineProps({
  parentSuccess: Boolean,
  parentCall: Boolean,
  reset: Boolean,
  subscribe: Boolean
})

const data = ref({
  name: '',
  email: '',
  message: '',
  subject: ''
});

const validate = () => {
  let error = false;
  Object.entries(data.value).map(([key, value]) => {
    if(key == 'name' && value.toString().trim().length == 0) error = true;
    if(key === 'email' && !emailRegex.value.test(value)) error = true;
  });
  return error;
}
const handleInput = (event,propertyName) => {
  data.value[propertyName] = event.target.value;
  if(isError.value) validate();
}

const resetForm = () => {
  data.value = {
      name: "",
      email: "",
      subject: "",
      message: ""
  }
  isChecked.value = false;
  isChecked2.value = false;
  isSent.value = false;
}

watch(props.parentCall, () => {
  if(!props.parentCall) resetForm();
})
watch(props.reset, () => {
  if(props.reset) resetForm();
})

const fetchData = (formdata) => {
  const request = {
      method: 'POST',
      body: formdata,
      mode: 'no-cors',
  }

  fetch('https://license.darmius.kz/mailsend', request)
  .then(response => {
      isSent.value = true;
      isSuccess.value = true;
      // resetForm();
  })
  .catch(e => {
      isSent.value = true;
      isSuccess.value = false;
      // resetForm();
  })
}
const send = (type) => {
  let form = type === 'subs' ? document.getElementById('subs') : document.getElementById('forma');

  isError.value = validate();
  form.reportValidity();
  if(type === 'subs' ? !isError.value : !isError.value && isChecked.value) {
    const formdata = new FormData();
    formdata.append('id', 'Cenalion Solution website');
    formdata.append('name', 'User name: ' + data.value.name);
    formdata.append('email', 'User email: ' + data.value.email);
    formdata.append('emailto', "info@cenalion.com");
    formdata.append('message', type === 'subs' ? "Request for the subscription to the newsletter!" : 'Message: ' + data.value.message);
    formdata.append('subject', type === 'subs' ? "Subscription to the newsletter" : 'Cenalion Solution website request' + data.value.subject);

    fetchData(formdata)
  }
}
onUnmounted(() =>{
  resetForm();
})
</script>

<style lang="scss" scoped>
.ui-modal {
  overflow-y: scroll;
  scrollbar-width: none;
  &-wrap {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 20;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.83);
  }
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  background: #1B1B1B;
  &.main {
    width: 80%;
    max-width: 1212px;
    height: 80%;
    max-height: 607px;
    padding: 33px 40px 70px 80px;
  }
  &.answer {
    width: 90%;
    max-width: 610px;
    height: auto;
    padding: 40px;
    .text-m {
      text-align: center;
    }
    img {
      transform: translateX(25px);
      width: 314px;
    }
    button {
      margin-top: 15px;
    }
  }
  &__content {
    width: 100%;
    height: 100%;
    .response {
      max-width: 300px;
      margin: 0 auto;
      gap: 22px;
      &.subscribe {
        max-width: 320px;
        gap: 0;
        & img {
          width: 95%;
        }
        form {
          width: 100%;
          margin-top: 24px;
          div + div {
            margin-top: 16px;
          }
          button {
            margin-top: 24px;
            width: 100%;
          }
        }
      }
      &__title {
        font-size: 32px;
        font-weight: 500;
        line-height: 46px; 
      }
    }
  }
  svg.cross {
    position: absolute;
    top: 0;
    right: 0; 
    z-index: 10;
    path {
      transition: all 0.3s ease;
    }
    &:hover {
      cursor: pointer;
      path {
        stroke-opacity: 1;
      }
    }
  }
  .picture {
    width: 80%;
    max-width: 305px;
  }
  .lines {
    position: absolute;
    left: -230px;
    bottom: 0px;
    width: 606px;
  }
  .left {
    width: 35%;
    margin-top: 20px;
    .title-mobile {
      display: none;
    }
  }
  .right {
    width: 60%;
    max-width: 556px;
    margin-top: 7px;
    .title {
      font-size: 32px;
      font-weight: 500; 
      line-height: 46px;
      margin-bottom: 22px;
    }
  }
  input[type="checkbox"] {
    // display: none;
    opacity: 0;
    position: absolute;
  }
}
.modal-form {
  gap: 16px;
  .top {
    gap: 16px;
    .input {
      width: 100%;
    }
  }
}
.custom-checkbox {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  .check-text {
    opacity: 0.5;
  }
  & span:nth-child(3) {
    padding-left: 12px;
  }  
  &:hover {
    cursor: pointer;
    .checkmark{
      border: 2px solid rgba(39, 39, 39, 0.40);
    }
  }
  & + .custom-checkbox {
    margin-top: 12px;
  }
  & span > span {
    text-decoration: underline;
  }
  .checkmark {
    display: inline-block;
    width: 20px;
    min-width: 20px;
    height: 20px;
    min-height: 20px;
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.16);
    background: rgba(255, 255, 255, 0.02);
    backdrop-filter: blur(13px);
    cursor: pointer;
    transition: border .3s ease;
  }
  input[type="checkbox"]:checked + .checkmark {
    border: 1px solid rgba(255, 255, 255, 0.16);
    background: #03B1E0;
    backdrop-filter: blur(13px);
    background-size: 60% 60%;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url("@/assets/icons/checked.svg");
  }
  input[type="checkbox"]:checked + .checkmark::before {
    display: block;
  }
}
@media (max-width: 1200px) {
   .lines {
    left: 0px;
    width: 400px !important;
   } 
}

@media (max-width: 1024px) {
  .ui-modal {
    &.main {
      max-height: none;
      padding: 40px 60px !important;
      height: auto;
    }
  }
  .ui-modal__content {
    flex-direction: column;
  }
  .lines, .title {
    display: none;
  }
  .left {
    width: 100% !important;
    margin-top: 0 !important;
    .title-mobile {
      display: block !important;
      color: #FFF;
      font-size: 28px;
      font-weight: 500;
    }
  }
  .right {
    width: 100% !important;
    max-width: none !important;
  }
}

@media (max-width: 768px) {
  .ui-modal {
    border-radius: 16px;
    &.main, &.answer {
      padding: 24px !important;
      width: calc(100% - 38px);
      max-width: 500px !important;
      max-height: 88%;
    }
    &-wrap {
      align-items: flex-end !important;
      padding-bottom: 24px;
    }
    .picture {
      width: 50%;
      margin-bottom: 10px;
    }
    &.answer {
      padding: 24px;
      .response {
        gap: 16px;
        &__title {
          font-size: 28px;
          line-height: normal;
          width: 100%;
        }
        img{
          width: 140px;
        }
        &.subscribe {
          align-items: flex-start !important;
          max-width: none !important;
          margin: 0;
          img {
            transform: translateX(0) !important;
          }
          form {
            margin-top: 0;
            div+div {
              margin-top: 12px;
            }
            button {
              margin-top: 32px;
            }
          }
        }
      }
    }
  }
  .modal-form {
    gap: 12px;
    .top {
      flex-direction: column;
      gap: 12px;
    }
    .textarea + .d-flex {
      margin: 12px 0 20px;
    }
  }
}
</style>