<template>
  <div class="container">
    <div class="nav-wrap d-flex align-center">
      <RouterLink to="/">
        <div class="h-logo">
          <img src="@/assets/icons/logo.svg" alt="logo" />
        </div>
      </RouterLink>
      <nav class="d-flex relative">
        <RouterLink to="/" class="nav-item text-m" :class="{'active': route.name == 'home'}">Home</RouterLink>
        <RouterLink to="/about" class="nav-item text-m" :class="{'active': route.name == 'about'}">About Us</RouterLink>
        <div class="d-flex align-center pointer" @mouseenter="isServices=true" @mouseleave="isServices=false">
          <p class="nav-item text-m">Services</p>
          <svg :class="{'rotated': isServices}" xmlns="http://www.w3.org/2000/svg" width="7" height="5" viewBox="0 0 7 5" fill="none">
            <path d="M3.06699 4.75C3.25944 5.08333 3.74056 5.08333 3.93301 4.75L6.09808 1C6.29053 0.666666 6.04996 0.25 5.66506 0.25L1.33494 0.25C0.950036 0.25 0.709473 0.666667 0.901924 1L3.06699 4.75Z" fill="white" fill-opacity="0.8"/>
          </svg>
          <div class="hidden d-flex column" :class="{'show': isServices}">
            <RouterLink to="/support" class="text-m" :class="{'active': route.name == 'support'}">IT Support and Maintenance</RouterLink>
            <RouterLink to="/custom-software" class="text-m" :class="{'active': route.name == 'custom'}">Custom Software Development</RouterLink>
            <RouterLink to="/enterprise-software" class="text-m" :class="{'active': route.name == 'enterprise'}">Enterprise Software Solutions</RouterLink>
            <RouterLink to="/cloud-software" class="text-m" :class="{'active': route.name == 'cloud'}">Cloud-Based Software</RouterLink>
            <RouterLink to="/quality-assurance" class="text-m" :class="{'active': route.name == 'quality'}">Quality Assurance and Testing</RouterLink>
            <RouterLink to="/agile-development" class="text-m" :class="{'active': route.name == 'agile'}">Agile Development</RouterLink>
          </div>
        </div>
        <RouterLink to="/software" class="nav-item text-m" :class="{'active': route.name == 'software'}">Software</RouterLink>
        <RouterLink to="/team" class="nav-item text-m" :class="{'active': route.name == 'team'}">Team</RouterLink>
        <RouterLink to="/contacts" class="nav-item text-m" :class="{'active': route.name == 'contacts'}">Contacts</RouterLink>
      </nav>
    </div>
  </div>  
</template>

<script setup>
import {ref} from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const isServices = ref(false);
</script>

<style lang="scss" scoped>
.container {
  padding: 0;
  width: 90%;
  max-width: 1280px;
  position: relative;
  //left: 50%;
  //transform: translateX(-50%);
  //top: 5vw;
  //z-index: 5;
}
.nav-wrap {
  //border-radius: 24px;
  //border: 1px solid rgba(255, 255, 255, 0.04);
  //background: #1b1b1b;
  //padding: 12px 26px;
  width: 100%;
  max-width: 935px;
}
.icon-wrap {
  font-size: 54px;
  width: 1em;
  height: 1em;
  margin-right: 12px;
}
.moving {
  overflow: hidden;
  width: 161px;
  gap: 15px;
  p {
    white-space: nowrap;
    color: #B1CA0B;
    animation: move 8s linear infinite;
  }
}
.hidden {
  display: none;
  position: absolute;
  top: 100%;
  left: 15%;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.02);
  background: rgba(255, 255, 255, 0.04);
  backdrop-filter: blur(37px);
  padding: 12px;
  gap: 4px;
  white-space: nowrap;
  &.show {
    display: flex;
  }
  .text-m {
    padding: 8px 12px;
    transition: all .3s ease;
    &:hover {
      cursor: pointer;
      color: #B1CA0B;
    }
    &.active {
      border-radius: 8px;
      border: 1px solid rgba(255, 255, 255, 0.08);
      background: rgba(255, 255, 255, 0.04);
      padding: 8px 12px;
      color: #B1CA0B;
    }
  } 
}
nav {
  gap: 40px;
  margin-left: 36px;
}
.nav-item {
  padding: 10px 0;
  transition: all .3s ease;
  & + svg {
    margin-left: 8px;
    transition: all .2s ease;
    &.rotated {
      rotate: 180deg;
    }
  }
  &.active {
    border-radius: 34px;
    padding: 10px 20px;
    border: 1px solid rgba(255, 255, 255, 0.04);
    background: rgba(255, 255, 255, 0.06);
    &:hover {
      background: rgba(255, 255, 255, 0.20);
    }
  }
  &:not(.active):hover {
    color: #B1CA0B;
  }
}
a {
  text-decoration: none;
}

@media (max-width: 1200px) {
  nav {
    gap: 16px;
    margin-left: 20px;
  }
  .nav-item {
    white-space: nowrap;
    font-size: 15px;
    &.active {
      padding: 10px;
    }
  }
  .icon-wrap {
    font-size: 44px;
  }
}

@media (max-width: 768px) {
  nav {
    display: none;
  }
}
</style>