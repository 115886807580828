import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { MotionPlugin } from '@vueuse/motion'
import './assets/scss/style.scss'
import './assets/scss/media.scss'
import './assets/scss/animation.scss'

const app = createApp(App)
app.use(router).use(MotionPlugin).mount('#app')
