<template>
  <div class="app">
    <router-view/>
  </div>
</template>

<style lang="scss">

// @import "@/assets/scss/style.scss";
.app {
  background: #121212;
  overflow: hidden;
}
</style>
