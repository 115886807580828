<template>
  <div class="container">
    <footer :class="{'anim': visible, 'hide': hide}">
      <img src="@/assets/icons/footer-logo.svg" alt="footer logo" />
      <div class="content d-flex wrap">
        <div class="top d-flex">
          <p class="text-m text-m-2">Address: Nikolaou Skoufa, 25, 3016, Limassol, Cyprus</p>
          <div class="text-m text-m-2">
            <p>Email: <a href="mailto:info@cenalion.com">info@cenalion.com</a></p>
            <a class="under" href="https://www.linkedin.com/company/cenalion/" target="_blank">Linkedin</a>
          </div>
        </div>
          
        <div class="right">
          <p class="color-mixed text-ml"><span>Let's open up the IT world together!</span> <br/>Get in touch with us for detailed information about cooperation.</p>
          <Button @click="isModal = true" text="Contact us" primary icon="arrow-right"></Button>
        </div>

        <div class="bottom d-flex text-s text-s-2">
            <p>©2024 CENALION HOLDINGS LTD</p>
            <RouterLink to="/privacy">Privacy Policy</RouterLink>
          </div>
      </div>
    </footer>
    <UiModal v-if="isModal" @close="isModal = false"></UiModal>
  </div>
</template>

<script setup>
import {ref} from "vue";
import Button from '@/components/Button.vue';
import UiModal from './Ui/UiModal.vue';
const props = defineProps({visible: Boolean, hide: Boolean})
const isModal = ref(false);
</script>

<style lang="scss" scoped>
footer {
  border-radius: 25px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.04);
  padding: 40px;
}
.container {
  padding-bottom: 70px;
}
.top {
  gap: 48px;
  max-width: 582px;
  width: calc(50% - 33px);
  p, a {
    color: rgba(255, 255, 255, 0.80);
  }
  a.under {
    text-decoration: underline;
  }
  div {
    white-space: nowrap;
  }
}
.bottom {
  gap: 31px;
  p, a {
    color: rgba(255, 255, 255, 0.60);
  }
}
.content {
  margin-top: 40px;
  gap: 66px;
  .right {
    width: calc(50% - 33px);
    text-align: right;
    button {
      margin: 50px 0 0 auto;
    }
  }
  .bottom {
    margin-top: -40px;
  }
}
a {
  cursor: pointer;
}

@media (max-width: 1400px) {
  .content {
    gap: 48px;
    .top {
      flex-direction: column;
      gap: 32px;
      width: 40%;
    }
    .right {
      width: calc(60% - 48px);
    }
  }
}

@media (max-width: 1200px) {
  .content {
    .top {
      width: 100%;
    }
    .right {
      width: 90%;
      text-align: left;
      button {
        margin-left: 0;
      }
    }
    .bottom {
      margin-top: 0;
    }
  }
}

@media (max-width: 768px) {
  .container {
    padding-bottom: 20px;
  }
  footer {
    border-radius: 10px;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    &.anim {
      transform: translateY(10%);
      opacity: 0;
      animation: moveCenter 1s ease forwards;
    }
    &.hide {
      opacity: 0;
    }
  }
  .content {
    margin-top: 32px;
    gap: 32px;
    justify-content: center;
    .right {
      width: 100%;
      text-align: center !important;
      button {
        margin: 32px auto 0;
      }
    }
    .bottom {
      flex-direction: column;
      gap: 8px;
      font-size: 14px;
    }
  }
}
</style>