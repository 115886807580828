<template>
    <header class="relative z-3" ref="target" id="header">
        <div class="container">
            <div class="d-flex align-center justify-between gap-10">
                <Nav />
                <div class="actions d-flex align-center">
                    <Button text="Connect" icon="chat" @click="isModal=true"></Button>
                    <div class="h-menu" @click="isMenu = !isMenu">
                        <svg v-if="!isMenu" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect x="6" y="6" width="4" height="4" rx="2" fill="white"/>
                            <rect x="14" y="6" width="4" height="4" rx="2" fill="white"/>
                            <rect x="14" y="14" width="4" height="4" rx="2" fill="white"/>
                            <rect x="6" y="14" width="4" height="4" rx="2" fill="white"/>
                        </svg>
                        <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M7 17L17 7M7 7L17 17" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
        <div class="hidden-menu d-flex column align-center" v-if="isMenu">
            <div class="hidden-menu__bg hidden-menu__bg--1"><img src="@/assets/icons/main-rec.svg" alt="bg"/></div>
            <div class="hidden-menu__bg hidden-menu__bg--2"><img src="@/assets/icons/main-rec.svg" alt="bg"/></div>
            <div class="hidden-menu__content d-flex column align-center">
                <RouterLink to="/" class="hidden-menu__item" :class="{'active': route.name == 'home'}">Home</RouterLink>
                <RouterLink to="/about" class="hidden-menu__item" :class="{'active': route.name == 'about'}">About Us</RouterLink>
                <div class="d-flex column align-center">
                    <div class="hidden-menu__item d-flex align-center gap-10" @click="isServices = !isServices">
                        <p>Services</p>
                        <svg class="icon" :class="{'active': isServices}" xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                            <path d="M4.61859 6.92857C4.34366 7.40476 3.65634 7.40476 3.38141 6.92857L0.288462 1.57143C0.0135332 1.09524 0.357194 0.5 0.907052 0.5L7.09295 0.500001C7.6428 0.500001 7.98647 1.09524 7.71154 1.57143L4.61859 6.92857Z" fill="white" fill-opacity="0.8"/>
                        </svg>
                    </div>
                    <div class="hidden-services d-flex column" v-if="isServices">
                        <RouterLink to="/support" :class="{'active': route.name == 'support'}">IT Support and Maintenance</RouterLink>
                        <RouterLink to="/custom-software" :class="{'active': route.name == 'custom'}">Custom Software Development</RouterLink>
                        <RouterLink to="/enterprise-software" :class="{'active': route.name == 'enterprise'}">Enterprise Software Solutions</RouterLink>
                        <RouterLink to="/cloud-software" :class="{'active': route.name == 'cloud'}">Cloud-Based Software</RouterLink>
                        <RouterLink to="/quality-assurance" :class="{'active': route.name == 'quality'}">Quality Assurance and Testing</RouterLink>
                        <RouterLink to="/agile-development" :class="{'active': route.name == 'agile'}">Agile Development</RouterLink>
                    </div>
                </div>
                <RouterLink to="/software" class="hidden-menu__item" :class="{'active': route.name == 'software'}">Software</RouterLink>
                <RouterLink to="/team" class="hidden-menu__item" :class="{'active': route.name == 'team'}">Team</RouterLink>
                <RouterLink to="/contacts" class="hidden-menu__item" :class="{'active': route.name == 'contacts'}">Contacts</RouterLink>
            </div>
            <div class="hidden-menu__bottom">
                <div class="bottom-links d-flex align-center">
                    <a href="mailto:info@cenalion.com">info@cenalion.com</a>
                    <a href="https://www.linkedin.com/company/cenalion/" target="_blank">LinkedIn</a>
                    <RouterLink to="/privacy">Privacy Policy</RouterLink>
                </div>
                <Button text="Contact us" icon="arrow-right" primary></Button>
            </div>
        </div>
    </header>
    <UiModal v-if="isModal" @close="isModal = false"></UiModal>
</template>

<script setup>
import {ref, watch} from "vue"; 
import { useRoute } from "vue-router";

import Button from "./Button.vue";
import UiModal from "./Ui/UiModal.vue";
import Nav from "@/components/Nav.vue";

const route = useRoute();
const isMenu = ref(false);
const isServices = ref(false);
const isModal = ref(false);

if(!isMenu.value) document.body.classList.remove('no-scroll')

watch(isMenu, () => {
    if(isMenu.value) document.body.classList.add('no-scroll')
    else document.body.classList.remove('no-scroll')
})
</script>

<style lang="scss" scoped>
header {
    padding: 27px 0;
}
.h-logo {
    width: 109px;
    height: 17px;
    img {
        width: 100%;
        height: 100%;
    }
}
.h-menu {
    display: none;
    transition: all 0.3s ease;
    svg.active rect {
        &:nth-child(1) {
            animation: icon1 1s forwards;
        }
        &:nth-child(2) {
            transform: translate(-4px, 4px);
        }
        &:nth-child(3) {
            transform: translate(-4px, -4px);
        }
        &:nth-child(4) {
            transform: translate(4px, -4px);
        }
    }
}

.hidden-menu {
    position: fixed;
    z-index: 10;
    width: 100%;
    height: calc(100% - 100px);
    background: #121212;
    left: 0;
    top: 100px;
    padding: 24px 0 20px;
    color: #FFF;
    &__content {
        z-index: 7;
        height: 100%;
        overflow-y: scroll;
        gap: 12px;
        width: 100%;
        text-align: center;
        padding-bottom: 24px;
    }
    &__item {
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        opacity: 0.8;
        text-decoration: none;
        text-align: center;
        justify-content: center;
        color: #fff;
        padding: 10px 32px;
        width: min-content;
        white-space: nowrap;
        &:visited, &:focus {
            color: #fff;
        }
        .icon.active {
            rotate: -180deg;
        }
        .icon {
            transition: all 0.3s ease;
        }
        &.active {
            border: 1px solid rgba(255, 255, 255, 0.10);
            border-radius: 46px;
            background: rgba(255, 255, 255, 0.10);
        }
    }
    &__bottom {
        z-index: 9;
        margin: 0 auto;
        border-radius: 16px;
        border: 1px solid rgba(255, 255, 255, 0.16);
        background: rgba(27, 27, 27, 0.60);
        backdrop-filter: blur(20px);
        width: calc(100% - 40px);
        height: 183px;
        bottom: 20px;
        left: 20px;
        padding: 32px 16px;
        div {
            justify-content: center;
        }
        button {
            margin: 0 auto;
            width: 100%;
            height: 47px;
        }
    }
    &__bg {
        position: absolute;
        width: 250px;
        height: 165px;
        top: 20px;
        right: -80px;
        z-index: 10;
        &--2 {
            bottom: 190px;
            top: auto;
            left: -130px;
            right: auto;
        }
        img {
            height: 100%;
        }
    }
}

.hidden-services {
    margin-top: 2px;
    a {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        opacity: 0.6;
        text-decoration: none;
        color: #fff;
        padding: 12px 24px;
        &.active {
            color: #B1CA0B;
            border: 1px solid rgba(255, 255, 255, 0.10);
            border-radius: 46px;
            background: rgba(255, 255, 255, 0.10);
        }
    }
}

.bottom-links {
    gap: 16px;
    flex-wrap: wrap;
    margin-bottom: 24px;
    a {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        opacity: 0.5;
        text-decoration: none;
        &:visited, &:focus {
            color: #fff;
        }
    }
}

@media (max-width: 1024px) {
    .actions button {
        padding: 8px 15px;
    }
}

@media (max-width: 768px) {
    header {
        z-index: 10;
        padding: 20px 0;
    }
    .actions {
        gap: 12px;
    }
    .h-menu {
        width: 34px;
        height: 34px;
        display: flex;
        flex-wrap: wrap;
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.10);
        background: rgba(255, 255, 255, 0.10);
        backdrop-filter: blur(12px);
        padding: 5px;
        svg {
            width: 24px;
            height: 24px;
        }
    }
    .hidden-menu {
        height: calc(100% - 73px);
        top: 73px;
    }
    .actions {
        button {
            height: 33px;
        }
    }
}

@media (max-width: 450px) {
    header {
        padding: 20px 0;
    }
    .hidden-menu__bottom {
        div {
            justify-content: flex-start;
        }
        button {
            margin: 0;
        }
    }
}
@media (max-width: 360px) {
    .actions button {
        padding: 9px 15px;
    }
}
</style>