<template>
  <div class="container screen-mt" :class="{'anim': visible, 'hide': hide}">
    <ScreenTitle type="center" title="People & Culture." width="80" :animate="visible">CENALION was established by a <span>collective of individuals</span> who are deeply passionate about their craft</ScreenTitle>
    <div class="d-flex content">
      <ImagesBlock
          :style="{ 'max-height': maxHeight}"
          v-motion
          :initial="{ x: -20, opacity: 0 }"
          :visibleOnce="{ x: 0, scale: 1, opacity: 1 }"
          :duration="700"
      ></ImagesBlock>
      <div class="d-flex column block-wrap">
        <div
          class="text-block"
          v-for="(i, id) in text"
          :key="i"
          v-motion
          :initial="{ x: 20, opacity: 0 }"
          :visibleOnce="{ x: 0, scale: 1, opacity: 1 }"
          :duration="600"
          :delay="id*100"
        >
          <p class="text-ml">{{ i }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted, onBeforeUnmount} from "vue";
import ImagesBlock from '@/components/blocks/ImagesBlock.vue';
import ScreenTitle from "@/components/ScreenTitle.vue";

const props = defineProps({visible: Boolean, hide: Boolean})

const maxHeight = ref(0);

const updateMaxHeight = () => {
  const rightHeight = window?.innerWidth < 768 ? "420px" : `${document.querySelector('.block-wrap').offsetHeight}px`;
  maxHeight.value = rightHeight;
};

onMounted(() => {
  updateMaxHeight();
  window.addEventListener('resize', updateMaxHeight);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateMaxHeight);
});

const text = ref([
  "Our company's profound faith resides in the potential of collaboration, embracing the notion of mutual growth through the harmonious blending of skill sets.",
  "CENALION's foundation is built upon transparency, recognizing that candid communication paves the path toward precision.",
  "It's with a sense of pride that we prioritize the team's welfare and equilibrium, reveling in our capacity to transform and adjust as we journey forward."
])
</script>

<style lang="scss" scoped>
.container {
  text-align: center;
  .text-l {
    width: 70%;
    margin: 20px auto 60px;
  }
  &.hide {
    opacity: 0;
  }
  &.anim {
    .text-block {
      opacity: 0;
      transform: translateX(10%);
      animation: moveRight 1s ease forwards;
      &:nth-child(2) {
        animation-delay: 0.2s;
      }
      &:nth-child(3) {
        animation-delay: 0.4s;
      }
    }
  }
}
.block-wrap {
  gap: 32px;
  height: min-content;
}
.content {
  gap: 32px;
  margin-top: 60px;
}
.text-block {
  border-radius: 25px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  background: rgba(255, 255, 255, 0.04);
  padding: 40px;
  text-align: left;
}

@media (max-width: 1200px) {
  .text-block {
    padding: 24px;
  }
}

@media (max-width: 1024px) {
  .content {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .content {
    margin-top: 32px;
    gap: 16px;
  }
  .block-wrap {
    gap: 16px;
  }
  .text-block {
    border-radius: 10px;
    text-align: center;
  }
  .container.anim {
    .text-block {
      transform: translateX(0) translateY(10%);
      animation: moveCenter 1s ease forwards;
      &:nth-child(1) {
        animation-delay: 0.8s;
      }
      &:nth-child(2) {
        animation-delay: 1.1s;
      }
      &:nth-child(3) {
        animation-delay: 1.4s;
      }
    }
  }
}
</style>