<template>
  <div class="container screen-mt little relative">
    <img class="bg" src="@/assets/icons/main-rec.svg" alt="background" />
    <ScreenTitle title="Services" width="80" >Check Out Our <span>Comprehensive Range of Services</span></ScreenTitle>
    <div class="services d-flex column">
      <div
        class="services__item"
        v-for="(item, index) in services"
        :key="index"
        @mouseenter="item.isHovered = true"
        @mouseleave="item.isHovered = false"
        v-motion
        :initial="{ x: 20, opacity: 0 }"
        :visibleOnce="{ x: 0, scale: 1, opacity: 1 }"
        :duration="500"
        :delay="index*100"
      >
        <div class="d-flex justify-between">
          <div>
            <p class="number text-mx">0{{ index+1 }}</p>
            <p class="text-ml text-ml-2 desktop">{{ item.title }}</p>
            <RouterLink :to="item.path" class="mobile"><p class="text-ml text-ml-2">{{ item.title }}</p></RouterLink>
          </div>
          <div class="d-flex justify-between align-center">
            <p class="text-m text-m-2 color-blur">{{ item.text }}</p>
            <RouterLink :to="item.path">
              <Button
                :class="{'hovered': item.isHovered}"
                text="Explore"
                round
                primary
              ></Button>
            </RouterLink>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import Button from '@/components/Button.vue';
import ScreenTitle from '@/components/ScreenTitle.vue';

const props = defineProps({visible: Boolean, hide: Boolean})

const services = ref([
  {
    title: "IT Support and Maintenance",
    text: "Delivering ongoing technical assistance and updates to ensure your systems operate smoothly.",
    isHovered: false,
    path: "/support"
  },
  {
    title: "Custom Software Development",
    text: "Crafting tailor-made software solutions to address your specific business needs and challenges.",
    isHovered: false,
    path: "/custom-software"
  },
  {
    title: "Enterprise Software Solutions",
    text: "Designing comprehensive software systems that streamline processes and enhance efficiency across your organization.",
    isHovered: false,
    path: "/enterprise-software"
  },
  {
    title: "Cloud-Based Software",
    text: "Building scalable and flexible software applications hosted on cloud platforms for easy access and management.",
    isHovered: false,
    path: "/cloud-software"
  },
  {
    title: "Quality Assurance and Testing",
    text: "Conducting rigorous testing to ensure your software is bug-free, reliable, and delivers a consistent user experience.",
    isHovered: false,
    path: "/quality-assurance"
  },
  {
    title: "Agile Development",
    text: "Following agile methodologies to ensure iterative and collaborative software development, promoting flexibility and adaptability.",
    isHovered: false,
    path: "/agile-development"
  },
])

</script>

<style lang="scss" scoped>
.container {
  overflow: hidden;
  .bg {
    position: absolute;
    top: -140px;
    right: -140px;
  }
}

.services {
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.04);
  background: rgba(255, 255, 255, 0.04);
  padding: 40px 40px 32px;
  gap: 12px;
  margin-top: 70px;
  &__item {
    padding: 0 0 22px;
    position: relative;
    .mobile {
      display: none;
    }
    .number {
      color: rgba(177, 202, 11, 0.80);
      margin-bottom: 20px;
    }
    .text-m {
      max-width: 337px;
      margin-top: 48px;
    }
    &:not(:last-child) {
      border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    }
    & > div {
      gap: 16px;
    }
  }
  button {
    margin: 4px 0 0 77px;
    opacity: 0;
    transition: all 0.4s ease;
    &.hovered {
      opacity: 1;
    }
  }
  a {
    text-decoration: none;
  }
}

@media (max-width: 1300px) {
  .services {
    &__item {
      .text-ml {
        width: 350px;
      }
    }
    button {
      margin: 4px 0 0 16px;
    }
  }
}

@media (max-width: 1024px) {
  .services {
    .text-ml {
      width: 250px;
    }
    button {
      display: none;
    }
    .mobile {
      display: block;
    }
    .desktop {
      display: none;
    }
  }
  .bg {
    width: 600px;
    top: -100px !important;
  }
}

@media (max-width: 768px) {
  .services {
    margin-top: 32px;
    border-radius: 10px;
    padding: 20px;
    &__item {
      padding: 0 0 24px;
      &:last-child {
        padding: 0;
      }
      & > div {
        flex-direction: column;
        gap: 0;
      }
      .text-m {
        margin-top: 8px;
        max-width: none;
      }
      .text-ml {
        width: 100%;
      }
      .number {
        margin-bottom: 16px;
      }
    }
  }
  .bg {
    width: 300px;
    right: -100px !important;
    top: -30px !important;
  }
}
</style>