<template>
  <div class="scrolled-wrap">
    <div class="scrolled d-flex screen-mt relative" @click="isClicked = true">
      <img src="@/assets/icons/walked.svg" alt="icon"/>
      <div class="moving d-flex">
        <p>Subscribe to newsletter</p>
        <p>Subscribe to newsletter</p>
      </div>
    </div>
    <UiModal v-if="isClicked" @close="isClicked = false" subscribe></UiModal>
  </div>
</template>

<script setup>
import {ref} from "vue";
import UiModal from "./Ui/UiModal.vue";
const isClicked = ref(false);
</script>

<style lang="scss" scoped>
.scrolled {
  &-wrap {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    cursor: pointer;
  }
  img {
    position: absolute;
    top: 54px;
    left: -1px;
    z-index: 2;
  }
  .moving {
    gap: 150px;
    overflow-x: hidden;
    width: 100%;
    white-space: nowrap;
  }
  p {
    font-size: 240px;
    font-weight: 700;
    line-height: normal;
    white-space: nowrap;
    animation: move 14s linear infinite;
  }
}

@media (max-width: 1440px) {
  .scrolled {
    &-wrap {
      margin-left: 80px;
    }
    img {
      width: 220px;
    }
    p {
      font-size: 190px;
      line-height: 270px;
    }
  }
}

@media (max-width: 1024px) {
  .scrolled {
    .moving {
      gap: 80px;
    }
    img {
      width: 150px;
    }
    p {
      font-size: 130px;
      line-height: 225px;
    }
  }
}

@media (max-width: 768px) {
  .scrolled {
    &-wrap {
      margin-left: 40px;
    }
    img {
      width: 110px;
      top: 20px;
    }
    .moving {
      gap: 40px;
    }
    p {
      font-size: 100px;
      line-height: 127.5px;
    }
  }
}

@media (max-width: 450px) {
  .scrolled {
    &-wrap {
      margin-left: 20px;
    }
  }
}
</style>