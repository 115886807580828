import { ref, onMounted, onBeforeUnmount } from 'vue';

const windowWidth = ref(window.innerWidth);

const updateWindowWidth = () => {
  windowWidth.value = window.innerWidth;
};

const useWindowWidth = () => {
  onMounted(() => {
    window.addEventListener('resize', updateWindowWidth);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('resize', updateWindowWidth);
  });

  return windowWidth;
};

export { useWindowWidth };
